import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'app/services/general.service';



@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
contactos:any
search:string=''
page:number=0

  constructor(
    private servicio:GeneralService
  ) { }

  ngOnInit(): void {
this.traercontactos()
    
  }

  traercontactos(){

this.servicio.traercontactos().subscribe(res=>{
  this.contactos=res
})


  }



  
  nextpage(){
    this.page +=5
        }
    
        prevpage(){
          if(this.page > 0){
    this.page -=5
          }
        }
    
    
    
    
        buscar(search){
          this.page =0
    this.search = search
        }

}
