import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'app/services/general.service';
import { Loading, Report } from 'notiflix';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  formulariologin:FormGroup
  usr:any
  constructor(
    public formulario:FormBuilder,
    private rutas:Router,
    private servicio:GeneralService
  ) {
    this.formulariologin = this.formulario.group({
      email:['',[Validators.email, Validators.required]],
      password:['',[Validators.required, Validators.minLength(4)]]
    })
   }

  ngOnInit(): void {
  }

  login(){

    this.servicio.login(this.formulariologin.value).subscribe(res=>{
      this.usr = res
      if (this.usr.success==0) {
  Loading.remove()
  Report.failure(
    'Radik',
    'Lo lamentamos, no encontramos tus credeciales, revisa de nuevo la informacion ingresada',
    'Okay',
  )
}else{
  Loading.remove()
  Report.success(
    'Radik',
    `Bienvenido ${this.usr[0].nombre}`,
    'Okay',
  )
    let token = btoa(this.usr[0].id)
    sessionStorage.setItem('token', token )
    this.rutas.navigateByUrl('/list-cont')

}



}, err =>{
console.log(err.message);

})

  }

}
