import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

//api : string = 'http://localhost/api_abogados/';
api : string = 'https://bufetedeabogadosmigratorios.com/api_abogados/';

  constructor(private cliente:HttpClient) { }

login(datoslogin){
return this.cliente.post(this.api+'?login',datoslogin)
}

traercontactos(){
  return this.cliente.get(this.api+'?Contactos')
}

registrarcontacto(datosform){

  let correo ={
    asunto:`${datosform.nombre} ha escrito en la pagina`,
    email:"felipeosorio1012@gmail.com",
    mensaje:`tienes un nuevo cliente por contactar su nombre es: ${datosform.nombre} <br> su correo es: ${datosform.email} <br>    esta interesado en: ${datosform.observaciones} `
  }

  this.enviarcorreo(correo)
console.log(correo);


  return this.cliente.post(this.api+'?register',datosform)
}


enviarcorreo(datoscorreo){


  this.cliente.post(this.api+'?correo',datoscorreo).subscribe(res=>{
    console.log(res);
    
  })

}



}
