import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtro'
})
export class FiltroPipe implements PipeTransform {

  transform(contactos: any, page:number = 0, search:string=''): unknown {
    if (search.length===0) {
      return contactos.slice(page,page + 5);
    }{
      const filtercontacs =  contactos.filter(e=>e.nombre.includes(search))
      return filtercontacs.slice(page,page + 5);
    }
  }

}
