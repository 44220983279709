import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { ProfileComponent } from './examples/profile/profile.component';
import { SignupComponent } from './examples/signup/signup.component';
import { LandingComponent } from './examples/landing/landing.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { AreasComponent } from './examples/areas/areas.component';
import { LoginComponent } from './admin/login/login.component';
import { ListComponent } from './admin/list/list.component';
import { LoginGuard } from './guards/login.guard';

const routes: Routes =[
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home',             component: ComponentsComponent },
    //{ path: 'user-profile',     component: ProfileComponent },
    { path: 'contacto',           component: SignupComponent },
    { path: 'convenio',          component: LandingComponent },
    {path:'areas',component:AreasComponent},
    { path: 'nucleoicons',      component: NucleoiconsComponent },
    {path:'admon',component:LoginComponent},
    {path:'list-cont',component:ListComponent,canActivate:[LoginGuard]}

];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
