import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GeneralService } from 'app/services/general.service';
import { Loading, Report } from 'notiflix';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {
  focus: any;
  focus1: any;
  formulariocontactol:FormGroup;

  constructor( private formulario: FormBuilder,
    private servicio:GeneralService) {
    this.formulariocontactol = this.formulario.group(
        {
            nombre:['',Validators.required],
            apellidos:['',Validators.required],
            email:['',[Validators.required,Validators.email]],
            telefono:['',Validators.required],
            observaciones:['',Validators.required]
        }
    )
}

  ngOnInit() {
    Loading.circle()
    Loading.remove()
  }

  
  enviarcontacto(){
    console.log(this.formulariocontactol.value);
    this.servicio.registrarcontacto(this.formulariocontactol.value).subscribe((res:any)=>{
      let respuesta = res

      if (res.succes==1) {
          Report.success(
              'BUFETE DE ABOGADOS',
              `Tu solicitud se a registrado con exito, pronto uno de nuestros Licenciados te contactara`,
              'Okay',
            )
            this.formulariocontactol.reset()
      }else{
          Report.failure(
              'BUFETE DE ABOGADOS',
              `Algo salio mal,intenta contactarnos a traves del icono de Whatsapp en la parte inferior izquierda`,
              'Okay',
            )
      }


  })

    
}

}
