import { Component, OnInit, Renderer2 } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Loading, Report } from 'notiflix';
import { GeneralService } from 'app/services/general.service';

@Component({
    selector: 'app-components',
    templateUrl: './components.component.html',
    styles: [`
    ngb-progressbar {
        margin-top: 5rem;
    }
    `]
})

export class ComponentsComponent implements OnInit {
    formulariocontacto :FormGroup
    page = 4;
    page1 = 5;
    focus;
    focus1;
    focus2;
    date: {year: number, month: number};
    model: NgbDateStruct;
    constructor( private renderer : Renderer2,
        private formulario: FormBuilder,
        private servicio:GeneralService) {
            this.formulariocontacto = this.formulario.group(
                {
                    nombre:['',Validators.required],
                    apellidos:['',Validators.required],
                    email:['',[Validators.required,Validators.email]],
                    telefono:['',Validators.required],
                    observaciones:['',Validators.required]
                }
            )
        }
    isWeekend(date: NgbDateStruct) {
        const d = new Date(date.year, date.month - 1, date.day);
        return d.getDay() === 0 || d.getDay() === 6;
    }

    isDisabled(date: NgbDateStruct, current: {month: number}) {
        return date.month !== current.month;
    }

    ngOnInit() {
        Loading.circle()
        let input_group_focus = document.getElementsByClassName('form-control');
        let input_group = document.getElementsByClassName('input-group');
        for (let i = 0; i < input_group.length; i++) {
            input_group[i].children[0].addEventListener('focus', function (){
                input_group[i].classList.add('input-group-focus');
            });
            input_group[i].children[0].addEventListener('blur', function (){
                input_group[i].classList.remove('input-group-focus');
            });
        }
        Loading.remove()
    }


    enviarcontacto(){
        console.log(this.formulariocontacto.value);
        this.servicio.registrarcontacto(this.formulariocontacto.value).subscribe((res:any)=>{
            let respuesta = res

            if (res.succes==1) {
                Report.success(
                    'BUFETE DE ABOGADOS',
                    `Tu solicitud se a registrado con exito, pronto uno de nuestros Licenciados te contactara`,
                    'Okay',
                  )
                  this.formulariocontacto.reset()
            }else{
                Report.failure(
                    'BUFETE DE ABOGADOS',
                    `Algo salio mal,intenta contactarnos a traves del icono de Whatsapp en la parte inferior izquierda`,
                    'Okay',
                  )
            }


        })

        
    }

}
